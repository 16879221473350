.index-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

body.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;

  .MuiDrawer-paper {
    background-color: rgba(18, 18, 18, 0.95);
  }

  .itinerary-container {
    background-color: #2c2c2c;
  }
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f1f3f4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &-header {
    width: 100%;
    height: var(--header-height);
    overflow: hidden;
  }
}

button {
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.4);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

:root {
  --primary-color: #f06ef5;
  --gradient-start: #ffafbd;
  --gradient-end: #ffc3a0;
  --white-bg: #ffffff;
  --shadow-color: rgba(0, 0, 0, 0.15);
  --text-color: #333333;
  --header-height: 64px;
  --bottom-nav-height: 56px;
  --mobile-bottom-spacing: calc(var(--bottom-nav-height) + env(safe-area-inset-bottom));
}

.MuiPaper-root {
  background-color: var(--white-bg);
  box-shadow: 0 12px 32px var(--shadow-color);
}

.MuiCard-root {
  background-color: var(--white-bg);
  box-shadow: 0 12px 32px var(--shadow-color);
  border-radius: 16px;
}

.MuiButton-root {
  border-radius: 9999px;
  text-transform: none;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(
    90deg,
    var(--gradient-start) 0%,
    var(--gradient-end) 100%
  );
  color: #ffffff;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
}

.MuiAppBar-root {
  background-color: var(--white-bg);
  color: var(--text-color);
  box-shadow: 0 4px 16px var(--shadow-color);
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
}

.MuiTypography-h1 {
  font-size: 2rem;
  font-weight: 600;
}

.MuiTypography-h2 {
  font-size: 1.75rem;
  font-weight: 600;
}
