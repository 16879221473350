.plan-stepper-container {
  text-align: left;
  position: absolute;
  top: calc(var(--header-height) + 12px);
  left: 12px;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 12px;
  width: 94%;
  z-index: 1000;
  border: 1px solid rgba(0, 255, 255, 0.2);
}

.plan-builder {
  height: calc(100vh - var(--mobile-bottom-spacing));
  overflow-y: auto;
}
