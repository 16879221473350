.plan-stepper-container {
  text-align: left;
  position: absolute;
  top: calc(var(--header-height) + 12px);
  left: 12px;
  background-color: white;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 12px;
  width: 334px;
  z-index: 1000;
  box-shadow: 0px 0px 12px 0px rgb(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 255, 255, 0.2);
}

.plan-stepper-header {
  display: flex;
  justify-content: space-between;

  .title {
    margin-right: auto;
    margin-left: 6px;
  }

  .button-group {
    display: flex;
    .button {
      color: white;
    }
  }
}

.plan-stepper-button {
  position: absolute;
  top: calc(var(--header-height) + 12px);
  left: 12px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 255, 255, 0.1);
  }
}

.plan-stepper-section {
  margin-bottom: 16;

  h3 {
    margin-bottom: 10px;
  }
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.chip {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
  padding: 6px 12px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 255, 255, 0.1);
  }

  &.active {
    background: rgba(0, 255, 255, 0.2);
  }
}

.range-slider {
  width: 100%;
  margin: 10px 0;
  --webkit-appearance: none;
  background: rgba(255, 255, 255, 0.1);
  height: 4px;
  border-radius: 2px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.plan-stepper-footer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;
}
