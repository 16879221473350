@import 'leaflet/dist/leaflet.css';

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.leaflet-control-container {
  position: absolute;
  z-index: 400;
}

.leaflet-pane {
  z-index: 1;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 56px;
  justify-content: center;
  text-align: center;
}