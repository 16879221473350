.activity-card-header {
  padding: 4px 4px !important;

  .MuiCardHeader-action {
    margin: 0px;
  }
}

.activity-badge {
  .MuiBadge-badge {
    background: #1976d2;
    color: white;
    font-size: 12px;
    height: 14px;
    min-width: 0px;
    padding: 0 4px;
  }
}

.activity-badge-complete {
  .MuiBadge-badge {
    background: #008504;
    color: white;
    font-size: 10px;
    height: 14px;
    min-width: 0px;
    padding: 0 4px;
  }
}

.activity-header-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .MuiIconButton-root {
    width: 100%;
    border-radius: 8px;
    transition: all 0.2s ease;
    position: relative;

    &:not(:disabled):hover {
      background: rgba(0, 0, 0, 0.08);
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    img {
      width: 36;
      height: 36px;
      border-radius: 4px;
      transition: transform 0.2s ease;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }
}

.activity-card {
  margin-bottom: 16px;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

  &.expanded {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }

  .expand-more {
    transform: rotate(0deg);
    transition: transform 0.3s;

    &.expanded {
      transform: rotate(180deg);
    }
  }
}

.activity-card-content {
  padding: 0px !important;
  display: flex;

  .activity-info-container {
    .time-chip {
      margin-bottom: 16px;

      .MuiChip-root {
        height: 32px;
        background: rgba(25, 118, 210, 0.08);
        border: none;

        .MuiChip-icon {
          color: #1976d2;
        }

        .MuiChip-label {
          font-weight: 500;
          padding-left: 8px;
        }
      }
    }
  }
}

.activity-card-details {
  padding: 20px;
  background: rgba(0, 0, 0, 0.02);
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  animation: cardExpand 0.3s ease-out;

  .MuiTextField-root {
    background: #fff;
    border-radius: 8px;
    transition: all 0.2s ease;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:disabled {
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.02);
    }

    &:not(:disabled):hover {
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .action-buttons {
    margin-top: 20px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    .MuiButton-root {
      min-width: 120px;
      transition: all 0.2s ease;

      &:hover {
        transform: translateY(-1px);
      }

      &.MuiButton-contained {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}

// Animation for card expansion
@keyframes cardExpand {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.activity-card-details {
  animation: cardExpand 0.3s ease-out;
}
