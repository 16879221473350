.filter-container {
  position: absolute;
  top: calc(var(--header-height) + 12px);
  right: 12px;
  background: white;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 12px;
  width: 300px;
  z-index: 1000;
  border: 1px solid rgba(0, 255, 255, 0.2);
}

.filter-header {
  display: flex;
  justify-content: space-between;

  .title {
    margin-right: auto;
  }

  .button-group {
    display: flex;
  }
}

.filter-button {
  position: absolute;
  top: calc(var(--header-height) + 12px);
  right: 16px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 255, 255, 0.1);
  }
}

.filter-section {
  margin-bottom: 20px;

  h3 {
    margin-bottom: 10px;
  }
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 12px;
}

.chip {
  border: 1px solid transparent;
  padding: 6px 12px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 255, 255, 0.1);
  }

  &.active {
    background: rgba(0, 255, 255, 0.2);
  }
}

.slider-container {
  margin: 0 12px;
  line-height: 1;
}

.range-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 8px;
}

.range-slider {
  width: 100%;
  margin: 10px 0;
  --webkit-appearance: none;
  background: rgba(255, 255, 255, 0.1);
  height: 4px;
  border-radius: 2px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  cursor: pointer;
}